import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import LoadScript from "vue-plugin-load-script";
import { loadScript } from "vue-plugin-load-script";


const app = createApp(App);
app.use(store);
app.use(router);
app.use(LoadScript);
app.mount("#app");

loadScript("/assets/vendor/aos/aos.js?v=1");
loadScript("/assets/vendor/bootstrap/js/bootstrap.bundle.min.js?v=1");
loadScript("/assets/vendor/glightbox/js/glightbox.min.js?v=1");
loadScript("/assets/vendor/isotope-layout/isotope.pkgd.min.js?v=1");
loadScript("/assets/vendor/swiper/swiper-bundle.min.js?v=1");
loadScript("/assets/vendor/waypoints/noframework.waypoints.js?v=1");
loadScript("/assets/vendor/php-email-form/validate.js?v=1");
loadScript("/assets/js/main.js?v=1");

