<template>
  <Header/>
   <Hero/>

  <main id="main">
  <About_us/>
  <Why_us/>
  <Skills/>
    <Services/>
    <Cta/>
    <Portfolio/>
    <Team/>
    <Pricing/>
    <Faq/>
    <Contact/>

  </main><!-- End #main -->

  <!-- ======= Footer ======= -->
  <Footer/>

  <div id="preloader"></div>
  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>
</template>
<script>
    import Header from "@/components/Header";
    import Hero from "@/components/Hero";
    import About_us from "@/components/About_us";
    import Why_us from "@/components/Why_us";
    import Skills from "@/components/Skills";
    import Services from "@/components/Services";
    import Cta from "@/components/Cta";
    import Portfolio from "@/components/Portfolio";
    import Team from "@/components/Team";
    import Pricing from "@/components/Pricing";
    import Faq from "@/components/Faq";
    import Contact from "@/components/Contact";
    import Footer from "@/components/Footer";
    export default {
        components: {Header,Hero,About_us,Why_us,Skills,Services,Cta,Portfolio,Team,Pricing,Faq,Contact,Footer}
    }

</script>

<style lang="scss">

</style>
